exports.facebook = function(data) {

    console.log(data)

    var name = data.titre_long;
    var desc = data.description == undefined ? '' : data.description;
    desc = desc.substring(0,120);


    if( name == undefined ) name = '';
    if( desc == undefined ) desc = '';

    // use abs URLs, not local paths
    FB.ui({
        method: 'feed',
        name: name,
        link: window.document.location.href,
        description: desc,
        display: 'popup'
    });
}

exports.twitter = function(data){

    var desc = data.description == undefined ? '' : data.description;
    desc = desc.substring(0,120);

    window.open('https://twitter.com/share?url=' +
        encodeURIComponent(window.document.location.href) +
        '&text=' +
        encodeURIComponent(desc)
    );
}
