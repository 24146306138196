var $ = require('jquery')
var context;
var mainGain;
var audio;

var j = 0;

var url = location.href.split('/');
var end = url.pop();

var sounds = [
    _ROOT + "/assets/sound/1.mp3", // ->
    _ROOT + "/assets/sound/2.mp3", // <-
    _ROOT + "/assets/sound/3.mp3", // hover
    _ROOT + "/assets/sound/4.mp3",
    _ROOT + "/assets/sound/5.mp3",
    _ROOT + "/assets/sound/6.mp3",
    _ROOT + "/assets/sound/7.mp3",
    _ROOT + "/assets/sound/8.mp3", // intro
    _ROOT + "/assets/sound/9.mp3", // menu
]

exports.init = function(data) {

    if (audio) return

    // console.log('audio: init');
    audio = document.getElementsByTagName('audio')[0];
    // effect = document.getElementsByTagName('audio')[0];

    function initAudioCtx() {

      try {
        // Fix up for prefixing
        window.AudioContext = window.AudioContext || window.webkitAudioContext;
        context = new AudioContext();
            // Load UI sound
            for (var i = 0 ; i < sounds.length ; i++) { loadSound(sounds[i], i) }
      } catch(e) {
        console.log('Web Audio API is not supported in this browser');
      }

    }

    // Init audio context
    initAudioCtx()

    // Set background music
    setMusic();

    audio = true;
}

function loadSound(url, i) {

    var xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'arraybuffer';

    // Decode asynchronously
    xhr.onload = function() {
        context.decodeAudioData(xhr.response, function(buffer) {
            sounds[i] = buffer;
            j++
            if(j == sounds.length) finishedLoading();
        });
    }

    xhr.onerror = function(e) {
        console.log("Error loading audio file", e)
    }

    xhr.send();
}

function finishedLoading() {

    console.log('audio: finishedLoading')
    var sound1;
    var sound2;
    var sound3;
    var sound4;
    var sound5;

    // Create two sources if sounds were played them both together.
    mainGain = context.createGain();
    var gain1 = context.createGain();
    var gain2 = context.createGain();
    var gain3 = context.createGain();
    var gain4 = context.createGain();
    var gain5 = context.createGain();

    mainGain.connect(context.destination)

    gain1.connect(mainGain);
    gain2.connect(mainGain);
    gain3.connect(mainGain);
    gain4.connect(mainGain);
    gain5.connect(mainGain);

    // gain1.gain.value = 0.15;
    // gain2.gain.value = 0.15;
    // gain3.gain.value = 0.15;
    // gain4.gain.value = 0.15;
    // gain5.gain.value = 0.10;

    mainGain.gain.value = 0.15;
    gain5.gain.value = 0.9;

    $(document).on('sound1', function() {
        sound1 = context.createBufferSource();
        sound1.buffer = sounds[0];
        sound1.start(0);
        sound1.connect(gain1);
    })

    $(document).on('sound2', function() {
        sound2 = context.createBufferSource();
        sound2.buffer = sounds[1];
        sound2.start(0);
        sound2.connect(gain2);
    })

    $(document).on('sound3', function() {
        sound3 = context.createBufferSource();
        random = Math.floor(Math.random() * (6 - 2 + 1) + 2);
        sound3.buffer = sounds[random];
        sound3.start(0);
        sound3.connect(gain3);
    })

    $(document).on('sound4', function() {
        sound4 = context.createBufferSource();
        sound4.buffer = sounds[7];
        sound4.start(0);
        sound4.connect(gain4);
    })

    $(document).on('sound5', function() {
        sound5 = context.createBufferSource();
        sound5.buffer = sounds[8];
        sound5.start(0);
        sound5.connect(gain5);
    })
}

function setMusic() {

    // Set background audio source
    var source = document.createElement('source');
    if (audio.canPlayType('audio/mpeg;')) {
        source.type = 'audio/mpeg';
        source.src = _ROOT + '/assets/sound/music.mp3';
    } else {
        source.type = 'audio/ogg';
        source.src = _ROOT + '/assets/sound/music.ogg';
    }

    audio.volume = 0;
    audio.appendChild(source);

    $(document).on('mute', function(e) {
        if (mainGain.gain.value == 0) {
            audio.volume = 0;
            mainGain.gain.value = 1;
            TweenMax.to($('.mute span'), 0.5, { opacity: 0.5 })
        } else {
            audio.volume = 0;
            mainGain.gain.value = 0;
            TweenMax.to($('.mute span'), 0.5, { opacity: 1 })
        }
    })
}
