var router = require('page');

//-------------- DEFAULTS

//-------------- VIEWS

// common views (header/footer/menu…)
var nav = require('modules/navigation.js');
var menu = require('modules/menu.js');
nav.init();
menu.init();

// page views :
// intro
var intro = require('modules/intro.js');
// list
var list = require('modules/grid.js');
// instagram list
var instagramList = require('modules/instagram-list.js');

var actualites = new list();
var live = new instagramList();
var oeuvres = new list();
var photo = new list();
// item
var fiche = require('modules/fiche.js');

//-------------- ROUTES

// root
router(['/','/fr','/en'], intro.enter);
router.exit(['/','/fr','/en'], intro.exit);

// static sections
var staticRoutes = [
	'/fr/bio',
	'/en/biography',
	'/fr/contact',
	'/en/contact',
	'/fr/atelier',
	'/en/studio'
]
router(staticRoutes, enterInstance);
router.exit(staticRoutes, exitInstance);

// lists
router(['/fr/actualites', '/en/news'], actualites.load, actualites.enter);
router.exit(['/fr/actualites', '/en/news'], actualites.exit);

router(['/fr/live', '/en/live'], live.load, live.enter);
router.exit(['/fr/live', '/en/live'], live.exit);

router(['/fr/oeuvres', '/en/artwork'], oeuvres.load, oeuvres.enter);
router.exit(['/fr/oeuvres', '/en/artwork'], oeuvres.exit);

router(['/fr/photos', '/en/photos'], photo.load, photo.enter);
router.exit(['/fr/photos', '/en/photos'], photo.exit);

// list : url ends with "/dash-separated-section-title/"
// router(/\/([a-z0-9*_-]+)\/?\/$/, liste.load, liste.enter);
// router.exit(/\/([a-z0-9*_-]+)\/?\/$/, liste.exit);

// item : url ends with "/numberId-dash-separated-title.html"
router(/\/\d+-[a-z0-9*_-]+?.html/, enterInstance);
router.exit(/\/\d+-[a-z0-9*_-]+?.html/, exitInstance);

router.base(_ROOT); // prod
router.start();

function enterInstance(ctx, next) {
	// Create new instance and initialise
	ctx.instance = new fiche();
	ctx.instance.load(ctx);
}

function exitInstance(ctx, next) {

	ctx.instance.exit(ctx, next);
}





