var xhr = require('xhr');
var TweenMax = require('gsap');
var Mustache = require('mustache');
// var classList = require('dom-classlist');

var navigation = require('modules/navigation.js');
var share = require('modules/share.js');
var audio = require('modules/audio.js');

module.exports = function() {

    var _this = this;
    var data;

    var contentClass = 'grid';
    var contentTag = 'div';
    var container;
    var content;
    var isServerRendered = false;

    // view specifics
    var $ = require('jquery');
    var Packery = require('packery_edited');
    var _ = require('lodash');

    // template / partials
    var template = require('views/grid.mustache');
    var tplListItem = require('views/listItem.mustache');

    // DOM elements
    var _document = $(document);
    var _window = $(window);
    var _nav = null;
    var _grid = null;
    var _img = null;
    var _art = null;
    var _item = null;
        // Navigation
    var _subtitle = null;
    var timeline;
        // Filter panel
    var _title = null;
    var _list = null;
    var _filtersClosePrompt = null;

    // Display filter : animation settings
    var filters = false; // Flag for animation
    var dist;
    var x;
    var y;
    var time;
    var time2;
    var opacity;
    var scale;
    var delay;
    var ease;

    // Packery
    var gutter = 10;
    var numCol = 5;
    var pckry;
    var columns;
    var width;

    // Date animation
    var _date = null;
    var anchors = {};
    var position;
    var year;

    var imagesLoaded;
    var isReturning = false;
    var exit;

    var set = false; // flag for layout actualités

    // Filter
    var target;
    var selected;
    var unselected;

    var clicked;
    var debounce;

    // LazyLoad
    var _spinner;
    var offset = 500; // Distance from bottom
    var numPages;
    var bottom;
    var next;
    var active = 1;
    var totalImagesLoaded = 0;

    var first; // Flag for first entry

    var fb;
    var tw;

    // View specific functions :

    // preload images
    function loadImages(img, preload) {

        // console.log("list: loadImages");
        // console.time("loadImages");

        var imgs = [];
        var total = img.length;
        var progress = 0;
        var step = 100 / total;
        var lazy = false;

        // If lazy loading get child of the container
        if (total == 1) {
            lazy = true;
            var _this = img;
            img = _this.find('img');

            if (!img.length) {
                // If there's no image, don't display it
                _this.remove();
                // And subtract it from the total
                totalImagesLoaded--;
            }
        }

        img.each(function(index, element) {

            var _self = $(this);
            imgs[index] = new Image();

            $(imgs[index]).attr('src', element.src)

            .on('load', function(){
                $(imgs[index]).remove();

                // animateLazy
                if (lazy) { animateLazy(_this, preload); return }

                // checkProgress is needed only at the beginning for animateIn
                checkProgress(progress += step);
            })

            .on('error', function(){

                // Destroy image if it can't be load
                $(imgs[index]).remove();
                _self.parent().remove();

                if (lazy) { return }
                checkProgress(progress += step);

            });
        });

        console.timeEnd("loadImages");
    }

    function checkProgress(progress) {

        if ( Math.round(progress) != 100) return;

        console.log('list: checkProgress')
        imagesLoaded = true;

        // Actualités, set
        if (data.isActualites && !set) layoutActualites();

        resize();
        if (pckry == undefined) initPackery();
        layout();

        // Hide loader
        animateIn();
    }

    // Lazyload ----------------------------------------

    function onScrollLazy() {
        return;

        var _this = $(this);
        var scrollTop = _this.scrollTop();
        var bottom = _grid.height() - _window.height();


        // Lazyload
        if (bottom < scrollTop + offset) {

            if (active < data.Pagin.nbPage) {

                // Disable lazyLoad when every page have been loaded
                _this.off('scroll', onScrollLazy);

                // displayFilter will be reset at the end of animateLazy
                _nav.find('.filters').off('click', displayFilter);
                console.log('list: --- displayFilter disable ');

                lazyLoad(false);
            }
        }
    }

    function lazyLoad(preload) {
        return;

        console.log('list: lazyLoad');

        // If preload is true, lazyLoad() was called from applyFilter

        // Show loader
        TweenMax.to(_spinner, 0.6, {
            autoAlpha: 1,
            scale: 1,
            ease: Expo.easeOut,
            force3D: true
        });

        // Get next page
        next = active + 1;

        // Get active page
        active = next;
        var url = location.href + 'page-' + next + '/?showJSON';

        xhr({
            url: url
        }, function (err, resp, body) {

            var data = JSON.parse(body);
            data = data.$FO_ITEM;

            // Get active page
            // active = data.Pagin.active;

            // Manipulate data
            var list = data.List;
            for (var i = list.length - 1; i >= 0; i--) {
                list[i].Attribut = _.values(list[i].Attribut);
                }
            data.List = list;
            list = null;

            // Append template
            _grid.append(Mustache.render(tplListItem, data));

            // Find last images
            var length = data.List.length;
            imgs = _grid.find('.art').slice(-length);

            totalImagesLoaded += length;
            imagesLoaded = false;

            // Hide them before animation
            imgs.hide();
            TweenMax.set(imgs, { autoAlpha: 0 });
            imgs.show();

            // Load image one by one
            imgs.each(function() {
                loadImages($(this), preload);
            });
        });
    }

    function animateLazy(art, preload) {
        return;

        // Resize each image before append it to packery
        art.width(width);

        // Add UI sound listener
        art.on('mouseenter', function(){
            _document.trigger('sound3');
        });

        // If there's filter, and the element must not be display, then return
        if (target !== undefined && !art.hasClass(target)) return;

        // Append element to the grid
        pckry.appended(art);

        // Count images loaded
        totalImagesLoaded--;

        // Animate element
        TweenMax.to(art, 1, {
            autoAlpha: 1,
            force3D: true,
            ease: Expo.easeOut,
            onComplete: function() {

                // Wait until every single image is loaded
                clearTimeout(debounce);
                debounce = setTimeout(function() {

                    // If Lazyload was called from applyFilter
                    if (preload) {

                        // If there's not enough images to display, load more
                        if ($('.' + target).length < 20) {
                            lazyLoad(true);
                            return;
                        }

                        // Else apply filter
                        applyFilter();

                    // If Lazyload was called on scroll
                    } else {

                        // Check is the user is already at the bottom despite new images
                        onScrollLazy();

                        if(!totalImagesLoaded) {

                            // Reset date anchors and columns
                            dateAnchors();
                            groupByColumns();

                            // Reactivate listener
                            _document.on('scroll', onScrollLazy);

                            // If there's already a listener for displayFilter disable it,
                            _nav.find('.filters').off('click', displayFilter);
                            // before enable it, to avoid multiple listener
                            _nav.find('.filters').on('click', displayFilter);

                            console.log('list: --- displayFilter enable ');

                        }
                    }

                    // Hide loader
                    TweenMax.to(_spinner, 0.6, {
                        autoAlpha: 0,
                        scale: 0.6,
                        delay: 0.3,
                        ease: Expo.easeOut,
                        force3D: true
                    });

                }, 500);
            }
        });
    }

    // Grid ----------------------------------------------

    function initPackery() {

        // console.log('list: initPackery');
        pckry = new Packery( content, {
            itemSelector: '.grid-item',
            transitionDuration: '0',
            gutter: 10,
            containerStyle: 'absolute'
        });
    }

    function layout() {

        // console.log('list: layout');

        // Set or reset layout
        resize();
        pckry.reloadItems();
        pckry.layout();
    }

    function groupByColumns() {

        console.log('list: groupByColumns');

        var pos = [];
        var selection = $('.grid-item');
        columns = [];

        // Get the left border position of each columns
        for (i = 0, l = selection.length ; i < numCol && i < l ; i++ ) {
            pos.push(selection.eq(i).position().left);
            columns[i] = selection.eq(i);
        }

        // Then sort each element according to its left border position
        for (i = 0, l = selection.length ; i < l ; i++ ) {
            for (j = 0 ; j < pos.length ; j++ ) {
                if (selection.eq(i).position().left == pos[j]) {
                    columns[j] = columns[j].add(selection.eq(i));
                    break;
                }
            }
        }
    }

    function dateAnchors() {
        return;

        console.log('list: dateAnchors');

        position = [];

        // Store date anchors positions
        _art.each(function() {

            var date = $(this).data('date');

            // If date is a period, get first year
            date = '' + date;
            if (date.indexOf('.') !== -1) date = date.split(' . ')[0];
            if (date.length === 0) return; // if empty
            date = parseInt(date);

            // Get first element and define it as anchor
            if (anchors[date] === undefined ) {
                anchors[date] = $(this).position().top;
            }

        });

        // Convert in array and reverse order
        for (date in anchors) { position.push([date, anchors[date]]) }
        position.reverse();
    }

    // case : Actualités ---------------------------------
    function layoutActualites() {

        console.log("list: layoutActualites")

        var _this;
        var random;
        var style;
        var color;
        var url;
        var img;

        _art.each(function(index, element) {

            var _this = $(this);

            // Set class to define dimensions in resize()
            if (!_this.hasClass('yes')) {
                random = Math.random() < 0.5 ? true : false;
                // random = index * 3 % 2

                random ? style = 'vertical' : style = 'horizontal';
                _this.addClass(style);
            }

            // Set each image in background to center them easily
            img = _this.find('img');
            url = img.attr('src');

            if (url !== undefined) {
                _this.css({ backgroundImage: "url('" + url + "')" });
                TweenMax.set(img, { autoAlpha: 0 });
            }

            // Set random color
            random = (Math.random() * 5 | 0) + 1;
            switch(random) {
                case 1:
                    color = '#EAF3D7';
                    break;
                case 2:
                    color = '#FCE0E3';
                    break;
                case 3:
                    color = '#F8E88F';
                    break;
                case 4:
                    color = '#F5DFA3';
                    break;
                case 5:
                    color = '#B4DBF4';
                    break;
                default:
                    color = '#FCE0E3'
            }

            // text formatting
            var date = _this.find('.date').get(0);
            date.innerHTML = date.innerHTML.split('').slice(0,4).join('')

           // _this.find('.details').css({ backgroundColor: color });
            set = true;
        });
        addHandlers();
    }

    // Filter ----------------------------------------------

    function displayFilter(e) {

        if (e !== undefined && e.type == "grid:displayFilter" && data.isActualites) filters = true;

        if (columns !== undefined) {

            var start = columns.length / 2;
            if (start % 1 !== 0) start -= start % 1;
            var middle = columns.slice(start);

            if (!filters) {

                console.log('list: displayFilter (open)');

                // Settings: animate in
                filters = true;
                dist = 100;
                x = gutter;
                y = 0;
                time = 0.8;
                time2 = 0.8;
                opacity = 1;
                scale = 1;
                delay = 0.3;
                ease = Expo.easeOut;

                if (columns.length % 2 === 0) dist = 50;

                _document.off('scroll', onScrollLazy);

            } else {

                console.log('list: displayFilter (close)');

                // Settings: animate out
                filters = false;
                dist = 0;
                x = 0;
                y = 500;
                time = 0.5;
                time2 = 0.2;
                opacity = 0;
                scale = 0;
                delay = 0;
                ease = Expo.easeIn;

                _document.on('scroll', onScrollLazy);
            }

            // If columns length is pair, move all columns
            if (columns.length % 2 === 0) {

                TweenMax.to(columns.slice(0, start), time, {
                    x: -x,
                    xPercent: -dist,
                    force3D: true,
                    ease: Expo.easeInOut
                });

            }

            TweenMax.to(middle, time, {
                x: x,
                xPercent: dist,
                force3D: true,
                ease: Expo.easeInOut
            });

            TweenMax.to(_list, time2, {
                autoAlpha: opacity,
                scale: scale,
                force3D: true,
                delay: delay,
                ease: ease
            });

            TweenMax.to(_title, time2, {
                autoAlpha: opacity,
                yPercent: -y,
                force3D: true,
                delay: delay,
                ease: ease
            });

            TweenMax.to(_filtersClosePrompt, time2, {
                autoAlpha: opacity,
                yPercent: y,
                force3D: true,
                delay: delay,
                ease: ease
            });
        }
    }

    function applyFilter(e) {

        console.log('list: applyFilter');

        // Target elements to filters
        if (e !== undefined) { target = $(e.target).data('store'); }
        else { target = undefined; }

        // Close filter panel
        if (filters) displayFilter();

        // Disable listener
        $('#navigation .exit-filter').off('click', applyFilter);
        _nav.find('.filters').off('click', displayFilter);
        console.log('list: --- displayFilter disable ');

        // Get the number ofcolumns to trigger animateColumnsIn()
        // when every columns are out
        var total = columns.length;

        // si on a un filtre à appliquer
        if (target !== undefined) {
            _subtitle.find('.title').remove();

            // Add filter name
            _subtitle.prepend('<div class="title"></div>');
            _subtitle.find('.title').text(target);

            // Select filtered elements
            selected = $('.' + target);
            unselected = $('.art').not(selected);

            // !IMPORTANT
            _window.trigger('resize');

        // RESET : no filter
        } else {
            // If another title is present, remove it

            // Select all elements
            selected = $('.art');
            unselected = null;

        }

        // Animate columns out
        TweenMax.set(columns, { y: 0 });
        TweenMax.staggerTo(columns, 0.5, {
            y: - _window.height(),
            autoAlpha: 0,
            force3D: true,
            ease: Expo.easeIn,
            onComplete: function() {
                // Trigger animation just once
                total--;
                if (!total) { animateColumnsIn(); }
            }
        }, 0.1);
    }

    function animateColumnsIn() {

            // Navigation animation
            // If filter activated: show filterName and hide, filter and back button
            if (target !== undefined) navigation.buttonState(4)
            else if (!$('.item').length) navigation.buttonState(3)


            // Columns animation ------------------------------
            // Change class name
            selected.addClass('grid-item');
            if (unselected !== null) unselected.removeClass('grid-item');

            // Reload packery (which selects only .grid-item elements )
            pckry.reloadItems();
            pckry.layout();

            // Then reset columns and date anchors with the new layout
            if (!exit) dateAnchors();
            groupByColumns();

            // Prepare animation
            TweenMax.set(columns, { y: _window.height() });

            // Reset scroll
            _document.scrollTop(0);

            // Animate columns in + title
            var total = columns.length;
            TweenMax.staggerTo(columns, 1.5, {
                y: 0,
                autoAlpha: 1,
                force3D: true,
                ease: Expo.easeOut,
                onComplete: function() {

                    // Check whether the columns have finished their animation
                    total--
                    if (!total) {

                        // Then reactivate listeners
                        _nav.find('.filters').off('click', displayFilter);
                        _nav.find('.filters').on('click', displayFilter);
                        $('#navigation .exit-filter').on('click', applyFilter);
                        console.log('list: --- displayFilter enable ')

                        // Collect garbage at the end of the animation, if user leaves the view
                        if (exit) removeHandlers();
                    }

                }
            }, 0.2)

            // Don't display title if there's no filter
            if (target == undefined) return

            if (_date !== null) _date.text(target)
            var tween = TweenMax.to(_date, 1.2, {
                opacity: 1,
                scale: 1,
                ease: Expo.easeOut,
                force3D: true,
                delay: 0.5,
                onComplete: function() {
                    tween.reverse();
                }
            })
    }

    function onScroll() {
        return;

        var _this = $(this);
        var scrollTop = _this.scrollTop();
        var current = year;

        // Display Date
        // position[i][1] -> anchors position
        // position[i][0] -> date to display
        for (i = 0 ; i < position.length ; i++) {
            if ( scrollTop >= position[i][1]) {
                year = position[i][0];
            }
        }

        if (current !== year && !filters && _date && !exit) {
            _date.text(year);
            TweenMax.to(_date, 0.5, {
                opacity: 1,
                scale: 1,
                ease: Expo.easeOut,
                onComplete: function() {
                    TweenMax.to(_date, 0.5, {
                        opacity: 0,
                        scale: 0.85,
                        force3D: true,
                        ease: Expo.easeIn
                    })
                }
            })
        }
    }

    function preventDoubleClick(e) {

        console.log('list: preventDoubleClick');
        if (clicked) { e.preventDefault() }
        else { clicked = true }
    }

    // framework functions
    //------------- ENTER
    var init = function() {

        // Collect DOM elements/nodes
        _nav = $('#navigation');
        _grid = $(content);
        _filters = $('#filters');
        _date = $('#date');
        _art = $('.art');
        _item = $('.grid-item');
        _img = _grid.find('img');
        _subtitle = _nav.find('.subtitle');
        _title = _filters.find('.title');
        _list = _filters.find('> ul');
        _filtersClosePrompt = _filters.find('.icon-cross');
        _counter = $('#counter');
        _spinner = $('.loader-spinner');
        fb = $('.icon-face, .res-face');
        tw = $('.icon-twit, .res-twit');

        // Restore flags
        imagesLoaded = false;
        filters = false;
        exit = false;
        clicked = false;
        first = true;

        if (!_grid) return;

        console.log('list: init');

        $('body').css('overflow', 'auto');

        // view specific CSS hooks
        $(container).removeClass().addClass('view-grid');
        $('.exit-filter').css({ display: 'block'});

        // Load images before animateIn
        if (!isReturning)loadImages(_img, true);
        else {
            TweenMax.to(_spinner, 0.6, {
                autoAlpha: 0,
                scale: 0.6,
                ease: Expo.easeIn,
                force3D: true,
                onComplete: function() {
                    _spinner.removeClass('colored');
                }
            })
        }

        // Init sound
        audio.init()

        // Data needed for Lazyload
        bottom = _grid.height() - _window.height();
        active = parseInt(data.Pagin.active);

        // Filters panel
        TweenMax.set(_title, { yPercent: -500 });
        TweenMax.set(_list, { scale: 0 });
        TweenMax.set(_filtersClosePrompt, { yPercent: 500 });

        // Set date style
        TweenMax.set(_date, { opacity: 0, scale: 0.85, yPercent: -50, force3D: true });

        // If another grid is present
        if($('.grid').length > 1) $(document).trigger('grid:exit')

        // Set navigation button style
        // Hide filters and back button and their container .subtitle
        if (data.isActualites) navigation.buttonState(1)

        // filtre -> fiche -> retour
        else if (target !== undefined) navigation.buttonState(4)

        // Show filters button, hide back button and show their container .subtitle
        else navigation.buttonState(3)
    }

    var animateIn = function() {

        console.log('list: isReturning', isReturning);
        console.log('list: animateIn \n ----------------------');

        TweenMax.to(_spinner, 0.6, {
            autoAlpha: 0,
            scale: 0.6,
            ease: Expo.easeIn,
            force3D: true,
            onComplete: function() {
                _spinner.removeClass('colored');
            }
        });

        TweenMax.to(content, 1, {
            autoAlpha: 1,
            ease: Expo.easeOut
        });

        if(isReturning) return;

        _document.scrollTop(0);

        if (data.isActualites) {

            // Case: Actualités

            TweenMax.set(_art, {
                autoAlpha: 0,
                scale: 0.4
            });

            TweenMax.staggerTo(_art, 1, {
                autoAlpha: 1,
                scale: 1,
                force3D: true,
                ease: Expo.easeOut
            }, 0.1);

        } else {

            // Case: Oeuvres

            // columns bottom-up animation
            TweenMax.set(columns, {
                opacity: 0,
                y: _window.height()
            });

            var total = columns.length;
            TweenMax.staggerTo(columns, 1.5, {
                y: 0,
                opacity: 1,
                force3D: true,
                ease: Expo.easeOut,
                onComplete: function() {
                    total--;
                    if (!total) {

                        // Add handlers at the end of the animation
                        console.log('addHandler: animateIn');
                        if (!isReturning) addHandlers();

                        // If user already at the bottom of the page
                        onScrollLazy();
                    }
                }
            }, 0.2);

        }
    }

    var resize = function(element) {
        console.log('list: resize')

        var W = _window.width()

        // Set columns width : oeuvres
        if (W < 640) { numCol = 2 }
        else if (W < 850) { numCol = 3 }
        else if (W < 1100) { numCol = 4 }
        else if (W < 1400) { numCol = 5 }
        else { numCol = 6 }

        // Base dimensions
        width = Math.floor(Math.max(0, ((_grid.width() + gutter) / numCol) - gutter));

        _art.add(_filters).width( width )
        // version liée au lazyload
        // var elements = $('.art').add('#filters');
        // elements.width( width )

        // dateAnchors();

        if (!data.isActualites) {
            if(first) first = false;
            else if( typeof pckry !== 'undefined') {
                pckry.on( 'layoutComplete', groupByColumns)
            }

            if(filters) displayFilter(); // close filters if they're opened

        } else {
            // Featured blocks
            $('.yes').width(width * 2 + gutter);
            $('.yes').height(width * 2 + gutter);

            // Vertical blocks
            $('.vertical').height(width * 2 + gutter);

            // Horizontal blocks
            $('.horizontal').width(width * 2 + gutter);
            $('.horizontal').height(width);
        }

    }

    var addHandlers = function() {

        console.log('list: addHandlers')

        _filtersClosePrompt.on('click', displayFilter);
        $('.filter li, #navigation .exit-filter').on('click', applyFilter);

        // if(isReturning) {
            _nav.find('.filters').on('click', displayFilter);
            console.log('list: --- displayFilter enable ');
        // }

        _document.on('scroll', onScrollLazy);
        _document.on('scroll', onScroll);

        addEventListener('orientationchange', resize);
        _window.on('resize', resize);

        // Sound events
        $('.art').on('mouseenter', function(){ _document.trigger('sound3');});

        // Prevent multiple clicks
        _art.has('img').find('a').off('click', preventDoubleClick);
        _art.has('img').find('a').on('click', preventDoubleClick);

        $(document).on('grid:displayFilter', displayFilter);

        // Set handler
        fb.on('click', function(e) {
            e.preventDefault()
            share.facebook(data)
        })

        tw.on('click', function(e) {
            e.preventDefault()
            share.twitter(data)
        })
    }

    //------------- EXIT
    var animateOut = function() {

        console.log('list: animateOut');
        TweenLite.to(content, 1, {
            autoAlpha: 0,
            onComplete: function() {

            // exit
            if(content != null){

                $(content).remove();
                content = undefined;
                target = undefined;

                // Reset packery and layoutActualites only if the user leave the view
                pckry = null;
                set = false;

                $(document).trigger('grid:enter')
                $(document).off('grid:enter')
                $(document).off('grid:exit', animateOut)
            }
        }});
    }

    var removeHandlers = function (e) {

        console.log('list: removeHandlers')

        // Reset filter
        if (timeline) {
            $('.exit-filter').off('click')
            $('.exit-filter').css({ display: 'none'})
        }

        removeEventListener('orientationchange', resize);

        exit = true;

        // If filter was applied, reset it
        // And collect garbage at the end of the animation
        /*if (target !== undefined) {

            console.log('removeHandlers -> filters -> collectGarbage')
            filters = true;
            target = undefined;
            applyFilter();
            return

        // If filter's panel is open, close it
        } else */
        if (filters) {
            displayFilter();
            setTimeout(function() { collectGarbage() }, 500);
            return

        } else {

            console.log('removeHandlers -> collectGarbage')
            collectGarbage();
        }
    }

    function collectGarbage() {
        if(_filtersClosePrompt == null) return;

        console.log('list: collectGarbage  \n ----------------------')

        _nav.find('.filters').off('click', displayFilter);
        console.log('list: --- displayFilter disable ')

        _filtersClosePrompt.off('click', displayFilter);
        $('.filter li, #navigation .exit-filter').off('click', applyFilter);

        _document.off('scroll', onScrollLazy);
        _document.off('scroll', onScroll);
        _window.off('resize', resize);

        $(document).off('grid:removeHandlers', removeHandlers);
        $(document).off('grid:addHandlers', addHandlers);
        $(document).off('grid:displayFilter', displayFilter);

        _art.has('img').find('a').off('click', preventDoubleClick)

        fb.off('click')
        tw.off('click')
        fb = null;
        tw = null;

        // garbage - collect elements
        // _nav = null;
        // _grid = null;
        _img = null;
        // _art = null;
        _item = null;
        _subtitle = null;
        _icon = null;
        _filterTitle = null;
        _title = null;
        _list = null;
        _filtersClosePrompt = null;
        _date = null;
        // columns = null;
    }

    // 1. query the model
    _this.load = function (ctx, next){

        // data = ctx.state.item.$FO_ITEM;
        data = ctx.state.$FO_ITEM;

        var path = ctx.pathname;
        var api = location.protocol + '//' + location.host + path + '?showJSON';

        // exit if data cached
        if (ctx.state.list){
            next();
            return;
        }
        console.log('list: load');

        // $(document).scrollTop(0);
        $(document).on('grid:removeHandlers', removeHandlers);
        $(document).on('grid:addHandlers', addHandlers);

        xhr({ url: api }, function (err, resp, body) {
            ctx.state.list = JSON.parse(body);
            ctx.save();
            next();
        });

        // Loader need to be shown here because init() usually appends
        // at the same time as loadImages() and load() take some time too
        _spinner = $('.loader-spinner');
        _spinner.addClass('colored');
        TweenMax.set(_spinner, { autoAlpha: 1, scale: 1 });
    }

    // 2. create the view from the model ( render, init, add handlers, animate In )
    _this.enter = function (ctx, next){

        console.log('list: enter')

        var menu = ctx.state.list.$FO_MENU[2];
        var titreRubrique;

        data = ctx.state.list.$FO_ITEM;

        // collect elements
        container = document.getElementById('page');

        // Check for acces-direct
        var accesDirect = document.body.querySelector('.acces-direct');

        // publish rubrique - navigation state
        titreRubrique = (typeof menu[data.template] !== 'undefined') ? menu[data.template].titre : '';
        $(document).trigger('navigation:changeTitle', titreRubrique);

        // DOM rendered by server
        if( accesDirect !== null ) {
            // Remove flag div
            accesDirect.parentNode.removeChild(accesDirect);

            content = container.querySelector('.'+contentClass);
            isServerRendered = true;

            // optimisable
            data.isOeuvres = $(content).hasClass('oeuvres_2716d') || $(content).hasClass('photos_c3f4a');
            data.isActualites = $(content).hasClass('actualites_73b9e');
            data.isLive = $(content).hasClass('live_85eb4');

            init();
            // addHandlers(); --> MOVED TO ANIMATE IN
            // handlers add at the end of the animation

            return;
        }


        // adjust data
        // data.isFR = ctx.state.list.$langueList.fr.active;

        var url = location.href;
        if (url.match('/en/')) data.isFR = false;
        else data.isFR = true;


        switch(data.template) {
            case "oeuvres_2716d":
            case "photos_c3f4a":
                data.isOeuvres = true;
                break;
            case "actualites_73b9e":
                data.isActualites = true;
                break;
            case "live_85eb4":
                data.isLive = true;
                break;
        }


        // Returning and already exists
        if (content) {
            isReturning = true;

            init(); // => loadImages => resize => animateIn …
            console.log('addHandler: enter');
            addHandlers();

            next();
            return;
        }

        // state
        console.log('list: render');
        isReturning = false
        isServerRendered = false;

        // manipulate data
        var list = data.List;
        for (var i = list.length - 1; i >= 0; i--) {
            list[i].Attribut = _.values(list[i].Attribut);
        };
        data.List = list;
        list = null;

        // render
        content = document.createElement(contentTag);
        content.innerHTML = Mustache.render(template, data, {
            listItem: tplListItem
        });
        content = content.children[0]; // get the actual component container

        // append
        $(content).addClass(contentClass);
        container.appendChild(content);

        // If another grid present, hide
        $(document).trigger('grid:exit');
        $(document).trigger('instagram-list:exit');

        if ($('.grid').length > 1) {
            $(document).on('grid:enter', function() {
                init(); // => loadImages => resize => animateIn …
                // addHandlers();
            })
            return
        }

        init(); // => loadImages => resize => animateIn …
        // addHandlers(); --> MOVED TO ANIMATE IN
        // handlers add at the end of the animation
    }

    // 3. destroy the view ( animate Out, remove handlers, delete DOM nodes, nullify vars )
    _this.exit = function (ctx, next){

        console.log('list: exit')
        // unbind, hide, remove

        removeHandlers();
        $(document).off('grid:exit', animateOut);
        $(document).on('grid:exit', animateOut);

        next();
    }

}
