var TweenMax = require('gsap');
var $ = require('jquery');
var navigation = require('modules/navigation.js');

var _menu;
var _polygon;
var points;

var _list;
var _title;
var _link;
var _clip;
var _text;
var _spacing;
var _background;
var elements;

var strokeWidth;
var strokeHeight;
var stroke;

var coordinates;
var cx;
var cy;

function getCoordinates(margin) {

    var o = margin;
    var w = window.innerWidth - o;
    var h = window.innerHeight - o;

    cx = window.innerWidth / 2;
    cy = window.innerHeight / 2;

    coordinates = [
            { x: o, y: o },
            { x: o, y: h },
            { x: w, y: h },
            { x: w, y: o }
        ];
}

function resize() {
   getCoordinates(10);

    for (i = 0; i < 4; i++) {

        // For Safari
        points[i] = points.getItem(i)
        TweenMax.set(points[i], {
            x: coordinates[i].x,
            y: coordinates[i].y
        })
    }
}

function tracking() {

    for (i = 0 ; i < _clip.length ; i++) {
        var width = _spacing.eq(i).width();

        //Can't use attr() because it's case sensitive
        _clip.get(i).setAttribute('textLength', width);
        _text.get(i).setAttribute('textLength', width);

        _link.eq(i).each(function() {
            _image = $(this).find('image');
            for (j = 0 ; j < _image.length ; j++) {
                _image.eq(j).attr('y', i * - $(this).height());
                _image.eq(j).attr('x', j + 1 * - 60);
            }
        })

    }
}

exports.init = function() {

    _menu = $('#menu');
    if(!_menu.get(0)) return;

    _polygon = document.getElementById('polygon');
    points = _polygon.points;

    _list = $('#list');
    _link = _list.find('a');
    _clip = _list.find('.clip');
    _text = _list.find('.text');
    _spacing = $('.spacing');
    _background = _menu.find('.background-container');
    strokeWidth = $('.stroke.top, .stroke.bottom');
    strokeHeight = $('.stroke.right, .stroke.left');
    stroke = strokeWidth.add(strokeHeight);

    _menu.find('.title').get(0).href = _ROOT;

    elements = _menu.find('.title, #list a, .social, .lang');

    tracking(); // FF doesn't support letter-spacing on svg

    // Disable link to current page 
    _link.on('click', function(event) {

        var current = window.location.href;
        var endCurrent = current.split('/');
        var lastCurrent = endCurrent[endCurrent.length - 2]
        var endTarget = this.href.split('/');
        var lastTarget = endTarget[endTarget.length - 2]

        // effet de fermeture différent selon qu'on change de controleur de vue ou non
        if (lastCurrent === lastTarget) {
            exports.close(true);
        } else {
            exports.close(false)
        }

        // reset nav-button state
        navigation.buttonState(1);
    });

    // Add UI sound listener
    _link.on('mouseenter', function(){
        $(document).trigger('sound3');
    });

    _list.find('.icon-cross').on('click', function() { exports.close(true) });

    TweenMax.set(_menu.find('.background-container'), { autoAlpha: 0 })
    $(window).on('resize', resize)
}

exports.open = function(margin) {

    var timeline = new TimelineMax();

    $(document).trigger('sound5');

    // If not intro, display close button
    if (!$('#loader').length) {
        elements = elements.add(_menu.find('.icon-cross'))
        _menu.find('.icon-cross').css({display: 'inline-block'})
    } else {
        _menu.find('.icon-cross').css({display: 'none'})
    }

    TweenMax.set(elements, { y: -10, autoAlpha: 0 })

    if(typeof margin !== 'number') margin = 10;

    // Animate rectangle
    TweenMax.set(_menu, { autoAlpha: 1 })

    getCoordinates(margin);

    var j = 4;
    for (i = 0; i < j; i++) {

        // For Safari
        points[i] = points.getItem(i)

        TweenMax.set(points[i], {
            x: cx,
            y: cy
        })

        TweenMax.to(points[i], 1.2, {
            x: coordinates[i].x,
            y: coordinates[i].y,
            delay: i * 0.1,
            ease: Expo.easeInOut,
            onComplete: function() {

                var timeline = new TimelineMax();

                // After last transition
                j--
                if (margin !== 0 || j) return

                // Adjust margin
                getCoordinates(10);

                for (j = 0; j < 4; j++) {
                    timeline.set(points[j], {
                        x: coordinates[j].x,
                        y: coordinates[j].y,
                    });
                }

                timeline.set(strokeWidth, { width: '100%', autoAlpha: 1 });
                timeline.set(strokeHeight, { height: '100%', autoAlpha: 1 });

                // Border: Animate in
                timeline.set(_background, { autoAlpha: 1 });

                timeline.to(strokeWidth, 1, {
                    width: '0%',
                    ease: Expo.easeInOut });

                timeline.to(strokeHeight, 1, {
                    height: '0%',
                    ease: Expo.easeInOut }, "-=1");
            }
        });
    }

    TweenMax.staggerTo(elements, 1.2, {
        y: 0,
        autoAlpha: 1,
        delay: 0.8,
        force3D: true,
        ease: Expo.easeOut
    }, 0.2)
}

exports.close = function(close) {

    $(document).trigger('sound1');
    
    var timeline = new TimelineMax();
    var current = window.location.href;

    timeline.to(strokeWidth, 1, {
        width: '100%',
        ease: Expo.easeInOut });

    timeline.to(strokeHeight, 1, {
        height: '100%',
        ease: Expo.easeInOut},  "-=1");

    timeline.set(stroke, {
        autoAlpha: 0
    })

    timeline.set(_background, { autoAlpha: 0 })

    if (close || this.href === current) {

        getCoordinates(0);

        for (i = 0; i < 4; i++) {

            points[i] = points.getItem(i); // Safari need getItem()

            // Adjust margin
            for (j = 0; j < 4; j++) {
                timeline.set(points[j], {
                    x: coordinates[j].x,
                    y: coordinates[j].y,
                });
            }

        }


        timeline.staggerTo(points, 0.6, {
            x: cx,
            y: cy,
            ease: Expo.easeInOut
        }, 0.10);

    } else {

        // First time menu is closed: end of the intro
        timeline.eventCallback("onComplete", function() {
            $(document).trigger('navigation:animateIn');
        });

    }

    timeline.staggerTo(elements, 0.4, {
        y: -10,
        autoAlpha: 0,
        force3D: true,
        ease: Expo.easeOut
    }, 0.05, "-=1")

    timeline.set(_menu, {
        autoAlpha: 0,
        onComplete: function(){
            TweenMax.set(_menu.find('.background-container'), { autoAlpha: 0 })
            $('#perspective').remove() }
    })
}
