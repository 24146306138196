var xhr = require('xhr');
var router = require('page');
var TweenMax = require('gsap');
var Mustache = require('mustache');
var classList = require('dom-classlist');
var menu = require('modules/menu.js');
var audio = require('modules/audio.js');

var contentClass = 'perspective';
var contentTag = 'div';
var container;
var content;
var serverRendered = false;

// view specific
$ = require('jquery');

var _window = $(window);
var _home;
var _title;
var _container;
var _counter;
var _loader
var _nav;

var orientation = 0;

var cursor = {
		x: 0,
		y: 0,
		tx: 0,
		ty: 0
};

var maxAngle = {
		x: 5,
		y: 5
	};

var template = require('views/intro.mustache');

// view specific functions

function fakeLoader() {

	// Init sound
    audio.init()

    i = 0;
    var inter = setInterval(function() {
        i++
        _counter.text(i)

        if (i == 100) {
            clearInterval(inter)

            $(document).trigger('sound4');
            TweenLite.to(_container, 1.5, {
                rotationX: 0,
                force3D: true,
                ease: Expo.easeOut,
                onComplete: function() {

                	// For resize
                	_container.css({
                		transform: 'rotateX(90deg) translateY(-50%) rotateX(-90deg)',
                		webkitTransform: 'rotateX(90deg) translateY(-50%) rotateX(-90deg)',
                		msTransform: 'rotateX(90deg) translateY(-50%) rotateX(-90deg)'
                	})

                	_window.on('mousemove', onMouseMove);
                	TweenLite.set(_loader, {autoAlpha: 0});
                }
            });

        }
    }, 10)
}

function onMouseMove(e) {

	// Get value between -100 and 100 ( -100% & 100% )
	cursor.x = 2 * (50 - Math.round( ( e.clientX / window.innerWidth ) * 100 ));
	cursor.y = 2 * (50 - Math.round( ( e.clientY / window.innerHeight ) * 100 ));

	// console.log(cursor)
	update();
}

function onOrientationChange(e) {
		updateOrientation();

		e.preventDefault();
}

function onMotionChange(e) {

	e.preventDefault();

	var beta = orientation === 1 ? -e.accelerationIncludingGravity.z : -e.accelerationIncludingGravity.z;
	var gamma = orientation === 1 ? -e.accelerationIncludingGravity.y : -e.accelerationIncludingGravity.x;

	cursor.x = 2 * (50 - ( gamma / 5 ) + 0.5 );
	cursor.y = 2 * (50 - ( beta / 5 ) - 0.5 );

	update();
}

function updateOrientation() {
	// Check if we're in portrait or landscape mode as we'll need
	// to use different values from the accelerometer
	if( window.orientation == 90 || window.orientation == -90 ) {
		orientation = 1;
	} else {
		orientation = 0;
	}
}

function update() {

	cursor.tx = cursor.x * maxAngle.x / 100;
	cursor.ty = cursor.y * maxAngle.y / 100;

	TweenMax.to(_title, 2, {
		rotationX: cursor.ty,
		rotationY: -cursor.tx,
		force3D: true,
		ease: Expo.easeOut
	})

	TweenMax.to('.background', 2, {
		rotationX: -cursor.ty,
		rotationY: cursor.tx,
		force3D: true,
		ease: Expo.easeOut
	})
}

//------------- ENTER
var init = function () {

	_home = $('#home');
    _container = $('#container');
    _title = _container.find('.title');
    _counter = $('#counter');
    _loader = $('#loader')
    _nav = $('#navigation');
    // _cross = $('#menu').find('.icon-cross');

    if (!_counter.length) return;
    console.log('Loader init');

    TweenMax.set(_nav, { autoAlpha: 0 });

    fakeLoader();

    $(content).on('click', menu.open);
    if ($('.grid').length) $(document).trigger('grid:exit');

    $(document).trigger('navigation:close')
}

var animateIn = function () {
}

var resize = function() {
	console.log('intro: resize')
}

var addHandlers = function () {

		console.log('intro: addHandlers')


		// addEventListener('orientationchange', onOrientationChange);
		// addEventListener('devicemotion', onMotionChange);

		updateOrientation();
}

//------------- EXIT
var animateOut = function (el) {
	menu.close();
}

var removeHandlers = function () {
	_window.off('mousemove', onMouseMove);
}

// 1. query the model
exports.load = function (ctx, next){
	next();
	return;
}

// 2. create the view from the model
exports.enter = function (ctx, next){
	console.log('intro: enter')

	var data;
	// collect elements
	container = document.getElementById('page');
    var accesDirect = document.body.querySelector('.acces-direct');

	// exit if DOM rendered by server
	if( accesDirect !== null ) {

        // Remove flag div
        accesDirect.parentNode.removeChild(accesDirect);

        content = container.querySelector( '#'+contentClass );
		serverRendered = true;

		init();
		resize();
		addHandlers();

		next();
		return;
	}

	// else render DOM
	console.log('intro: render')
	content = document.createElement(contentTag);
	content.innerHTML = Mustache.render(template, {'isIntro': true, 'root_img': WEB_ROOT_IMAGES});
	content = content.children[0]; // get the actual component container

	// append
	$(content).attr("id", contentClass);
	$(container).prepend(content);

	init();
	resize();
	addHandlers();

	animateIn();

	next();
}

exports.exit = function (ctx, next){
	console.log('intro: exit');

	var el = document.querySelector('#'+contentClass);
	if (el === null) {
		next();
		return;
	}

	_window.off('mousemove', onMouseMove);

	// hide, unbind, remove
	animateOut(el);
	// removeHandlers();

	next();
}
