var xhr = require('xhr');
var router = require('page');
var TweenLite = require('gsap');
var Mustache = require('mustache');

// var grid = require('modules/grid.js');
var navigation = require('modules/navigation.js');

var ficheZoom = require('modules/fiche_zoom.js');
var share = require('modules/share.js');


module.exports = instance;

function instance() {

    var _this = this;
    var data;
    var lang;

    var container;
    var content;
    var isServerRendered = false;

    // view specific
    var contentClass = 'item';
    var contentTag = 'div';

    var $ = require('jquery');
    var nanoscroller = require('lib/jquery.nanoscroller.js');
    var swiper = require('swiper');

    // template / partials
    var partials = [];
    var template = require('views/fiche.mustache');
    partials['galerie'] = require('views/galerie.mustache');
    partials['description'] = require('views/description.mustache');
    partials['contact'] = require('views/contact.mustache');

    // DOM elements
    var _window;
    var _nav = null;
    var _details = null;
    var _leftPanel = null;
    var _rightPanel = null;
    var _cross = null;
    var _title = null;
    var _burger = null;
    var _social = null;
    var _zoom = null;
    var _nano = null;
    var _fullscreen = null;
    var _container = null;
    var _active = null;
    var _swiper = null;
    var gallery;
    var direction;
    var leftElements = null;
    var rightElements = null;
    var timeline = null;
    var hideBackButton = false;

    var _input = null;
    var _submit = null;
    var _form = null;
    var _info = null;

    var fb;
    var tw;

    var zoom;
    var WIDTH; // window width

    // view specific functions / modules

    // modules/fiche_galerie.js
    function initSwiper() {

      _swiper = $('.swiper-container');
      if (!_swiper.length) return;

        // console.log('fiche: swiper init');

        gallery = new Swiper(_swiper, {

            direction: direction,
            speed: 600,
            keyboardControl: true,
            simulateTouch: false,
            mousewheelControl: false,
            setWrapperSize: true,
            onSlideChangeStart: function() {

                TweenMax.to(_container, 0.4, {
                    scale: 1,
                    x: 0,
                    y: 0,
                    ease: Expo.easeOut});
          },

          onSlideChangeStart: function() {
                zoom.resetControl();
          },

          onSlideChangeEnd: function() {
                var activeIndex = gallery.activeIndex;
                zoom.selectActive(activeIndex);
          },

          onInit: function() {

            TweenMax.to(_swiper.find(_container), 1, {
                autoAlpha: 1,
                scale: 1,
                ease: Expo.easeOut });
          },

          // Navigation arrows
          nextButton: '.swiper-button-next',
          prevButton: '.swiper-button-prev'

      });
    }

    var submitForm = function(self, token){

       // e.preventDefault();

        var _this = self;
        var elements = _this.find('input, textarea');
        var message;
        var color;

        var text = {
            fr: {
                whiteSpace: 'L’email est obligatoire',
                invalidEmail: 'Mauvais format d’email',
                success: 'Merci. Votre message a été envoyé avec succès.'
            },
            en: {
                whiteSpace: 'Email address is needed',
                invalidEmail: 'Wrong email format',
                success: 'Thanks. Your messages has been sent successfully.'
            }
        }; 

        $.ajax({
            url: document.location.href,
            type: 'post',
            data: 'todo=contact&' + _this.serialize()+'&token='+token,

            beforeSend: function() {
                elements.removeClass('error');
                $('#submit').fadeOut();
            },

            success: function(data) {
                try {

                    data = $.parseJSON(data);
                    $.each(data, function(i, e) {

                        if (e.level == 'error') {
                            $('input[name=' + e.field +'], textarea[name='+ e.field +']').addClass('error');

                            // If there's error, check if it's because value is only white space
                            // or if the email invalid email
                            if (e.field == 'email') {

                                var value = $('input[name=' + e.field + ']').val();
                                color = '#ff341a';

                                if (!/\S/.test(value)) {
                                    message =  text[lang].whiteSpace;
                                } else {
                                    message =  text[lang].invalidEmail;
                                }
                            }
                            if(e.field == 'g-recaptcha-response'){
                                message = e.msg;   
                            }
                            $('#submit').fadeIn();
                        }

                        if (e.level == 'success') {

                            color = '#5eb00f'
                            message =  text[lang].success

                        }

                    });

                    // Display message
                    var timeline = new TimelineMax();
                    var delay;
                    _info.html(message)

                    // Info form
                    timeline.set(_info, {
                        color: color,
                        autoAlpha: 0,
                        xPercent: 100
                    })

                    timeline.to(_info, 1, {
                        autoAlpha: 1,
                        xPercent: 0,
                        force3D: true,
                        ease: Expo.easeOut,
                    })

                }
                catch(e) {
                    alert('erreur');
                }
            }
        });
    };

    // framework functions

    //------------- ENTER
    var init = function () {
        // collect elements
        _window = $(window);
        _nav = $('#navigation');

        _details = $('.item');
        _details = _details.last();

        _leftPanel = _details.find('.left-panel');
        _rightPanel = _details.find('.right-panel');
        leftElements = _leftPanel.find('.container:eq(0)');
        rightElements = _rightPanel.find('.date, .dimensions, .title, .subtitle, .shadow');
        _zoom = _details.find('.zoom');

        _nano = _details.find('.nano');
        _swiper = _details.find('.swiper-container');
        _container = _details.find('.container');

        _cross = _nav.find('.back');
        _title = _nav.find('.title, .icon-cross');
        _burger = _nav.find('.burger-menu');
        _social = _nav.find('.social').find('.icon');

        _input = _details.find('input:not([type="submit"])');
        _submit = _details.find('#submit');
        _form = _details.find('form');
        _info = _details.find('.info');

        fb = $('.icon-face, .res-face');
        tw = $('.icon-twit, .res-twit');


        if (!_details) return;
        console.log('fiche: init')

        // Zoom
        zoom = new ficheZoom();

        if (_nano) {
            // console.log('fiche: nanoscroller init')
            _nano.nanoScroller();
            _nano.nanoScroller({ sliderMaxHeight: 50 });
        }

        // view specific CSS hooks
        $(container).removeClass().addClass('view-item');

        // Prepare timeline for animateIn and animateOut
        setTimeline();
        TweenMax.set(_container, { scale: 1 })

        // Set navigation button style-
        // _cross.css({ display: 'block' })
        // _nav.find('.exit-filter, .subtitle .title').css({ display: 'none'})

        var url = location.href.split('/');
        var end = url.pop();

        // Hide filters, show back button
        // Fiche Oeuvres et Actualités
        if (end.match('.html')) {
            navigation.buttonState(2);
            _cross.get(0).href = url.join('/')+'/';

        // Hide filters and back button and their container .subtitle
        // Fiche Bio, Atelier, Contact
        } else {
            navigation.buttonState(1);
        }
    };

    var setTimeline = function(){

        // console.log('fiche: setTimeline')
        timeline = new TimelineMax();

        // Prevent fast click on back button to ensure
        // animation ends
        var preventClick = true;
        _cross.on('click', function(e) {
            if (preventClick) e.preventDefault();
            else return true;
        });

        // Animate In ------------------------------
        end = location.href.split('/').pop();

        // Callback on beginning of animateIn
        timeline.eventCallback("onStart", function() {

            // Play UI Sound
            if (end.match('.html')) $(document).trigger('sound1');
        });

        // Callback at the end of animateIn
        timeline.eventCallback("onComplete", function() {

            // Exit grid when animateIn
            // case: Bio, Atelier, Contact
            if ($('.grid').length && !end.match('.html')) {
                $(document).trigger('grid:exit');
            }

            preventClick = false;

        });

        // Animate out --------------------------

        _cross.on('click', function() {
            $(document).trigger('sound2');
        });

        // Callback at the end of animateOut
        timeline.eventCallback("onReverseComplete", function() {
            // exit
            if(content != null) content.parentNode.removeChild(content);
            $(document).trigger('fiche:enter');
            $(document).off('fiche:enter');
        });


        // BEGINNING OF THE ANIMATION
        TweenMax.set(content, { autoAlpha: 1 });
        timeline.set(_details, { autoAlpha: 1 });

        // Hide panels and set opacity of the inner elements
        timeline.set(leftElements.add(rightElements), { autoAlpha: 0 });
        timeline.set(leftElements.add(_leftPanel), { xPercent: -100 });

        if (_window.width() > 765) {
            timeline.set(rightElements.add(_rightPanel), { xPercent: 100 });
        } else {
            timeline.set(rightElements.add(_rightPanel), { xPercent: -100 });
        }

        // END OF THE ANIMATION
        timeline.to(_leftPanel, 1.5, {
            xPercent: 0,
            autoAlpha: 1,
            force3D: true,
            ease: Expo.easeOut
        })

        timeline.staggerTo(leftElements, 1.5, {
          xPercent: 0,
          autoAlpha: 1,
          force3D: true,
          ease: Expo.easeOut
        }, 0.1, "-=1.5")

        timeline.to(_rightPanel, 1.5, {
            xPercent: 0,
            autoAlpha: 1,
            force3D: true,
            ease: Expo.easeOut
          }, "-=1.5")

        timeline.staggerTo(rightElements, 1.5, {
          xPercent: 0,
          autoAlpha: 1,
          force3D: true,
          clearProps: 'transform',
          ease: Expo.easeOut
        }, 0.1, "-=1.5")

        // Choose the state of the animation :
        // if server rendered force the animation to the end
        if (isServerRendered) timeline.progress(1)
    };

    var animateIn = function () {

        console.log('fiche: animateIn \n ----------------------');
        // $('body').css('overflow', 'hidden');
        timeline.play();
    };

    var resize = function() {

        console.log('fiche: resize');

        WIDTH = _window.width();

        // Change swiper direction
        // Must create another because you can't change direction
        // after initialization

        if (WIDTH < 765 && direction !== 'horizontal') {
            direction = 'horizontal';
            if (gallery) gallery.destroy(true, true);
            initSwiper();
            console.log('change direction');
        } else if (WIDTH > 765 && direction !== 'vertical') {
            direction = 'vertical';
            if (gallery) gallery.destroy(true, true);
            initSwiper();
        }
    };

    var addHandlers = function () {
        console.log('fiche: addHandlers');

        // this value is define in navigation.js : setHorizontalAnimation or setVerticalAnimation
        navAnimDuration = 1.6;

        // Wait until navigation animation ends, because zoom messes with it
        setTimeout(function() {
            _zoom.on('click', zoom.displayFullscreen);
        }, navAnimDuration * 1000);


        _form.on('submit', function(e){
            var self = $(this);
            e.preventDefault();
            grecaptcha.ready(function() {
                grecaptcha.execute(_RECAPTCHA_PUBLIC, {action: 'submit'}).then(function(token) {
                    submitForm(self,token);
                });
            });
            
        });
        _window.on('resize', resize);

        // Set social handler
        fb.on('click', function(e) {
            e.preventDefault();
            share.facebook(data);
        });

        tw.on('click', function(e) {
            e.preventDefault();
            share.twitter(data);
        });
    };

    //------------- EXIT
    var animateOut = function (el) {

        // console.log('fiche: animateOut')
        timeline.timeScale(2); // Multiply speed by two
        timeline.reverse();
    };

    var removeHandlers = function () {
        console.log('fiche: removeHandlers \n ----------------------')

        // contact
        _form.off('submit', submitForm)

        _zoom.off('click', zoom.loadZoomImage);
        _window.off('resize', resize);

        // _cross.css({ display: 'none' })
        _cross.off('click')

        fb.off('click')
        tw.off('click')
        fb = null;
        tw = null;

        // collect garbage
        _nav = null;
        _details = null;
        _leftPanel = null;
        _rightPanel = null;
        _cross = null;
        _title = null;
        _burger = null;
        _social = null;
        _zoom = null;
        _input = null;
        _submit = null;
        _nano = null;
        _fullscreen = null;
        _container = null;
        _active = null;
        _swiper = null;
        _form = null;
        timeline = null;
        leftElements = null;
        rightElements = null;
        zoom = null;
    };

    // 1. query the model
    _this.load = function (ctx){
        var path = ctx.pathname;
        var api = location.origin + path + '?showJSON';

        // exit if data cached
        if (ctx.state.item){
            enter(ctx);
            return;
        }
        console.log('fiche: load');

        xhr({ url: api }, function (err, resp, body) {
            ctx.state.item = JSON.parse(resp.body);
            ctx.save(); // cache data / save state
            enter(ctx);
        });
    };

    // 2. create the view from the model ( render, init, add handlers, animate In )
    function enter(ctx){
        console.log('fiche: enter');

        data = ctx.state.item.$FO_ITEM;
        if ( ctx.state.item.$LANGUE.langue == 'fr' ) data.isFr = true;
        lang = ctx.state.item.$LANGUE.langue;

        var menu = ctx.state.item.$FO_MENU[2];
        var titreRubrique;

        // collect elements
        container = document.getElementById('page');

        // Check for acces-direct
        var accesDirect = document.body.querySelector('.acces-direct');
        // ^ definitely should be a class instead of id: there can be 2 fucking views with same controller. deal with it.

        // publish navigation state
        titreRubrique = (typeof menu[data.template] !== 'undefined') ? menu[data.template].titre : '';
        $(document).trigger('navigation:changeTitle', titreRubrique);

        // exit if DOM rendered by server
        if( accesDirect !== null ) {

            // Remove flag div
            accesDirect.parentNode.removeChild(accesDirect);

            // Get content
            content = container.querySelector( '.'+contentClass );
            console.log('isServerRendered')
            isServerRendered = true;
            $(document).trigger('navigation:animateIn');

            init();
            resize();
            addHandlers();

            return;
        }

        // state
        console.log('fiche: render')
        isServerRendered = false;

        // use data : partials logic
        switch(data.template) {
            case "oeuvres_2716d":
            case "photos_c3f4a":
                data.isOeuvres = true;
                break;
            case "bio_1c924":
                data.isBio = true;
                break;
            case "actualites_73b9e":
                data.isActualites = true;
                break;
            case "atelier_8e81c":
                data.isAtelier = true;
                break;
            case "contact_7ec0a":
                data.isContact = true;
                break;
        }


        // render
        content = document.createElement(contentTag);
        content.innerHTML = Mustache.render(template, data, {
            galerie: partials['galerie'],
            description: partials['description'],
            contact: partials['contact']
        });
        content = content.children[0];

        // append
        $(content).attr("class", contentClass);
        $(container).append(content);

        TweenMax.set(content, { autoAlpha: 0 })

        if ($('.item').length > 1) {
            $(document).on('fiche:enter', function() {
                init();
                resize();
                addHandlers();

                animateIn();
            })
            return
        }

        init();
        resize();
        addHandlers();

        animateIn();
    }

    // 3. destroy the view ( animate Out, remove handlers, delete DOM nodes, nullify vars )
    _this.exit = function (ctx, next){
        /*console.log('fiche: exit')*/

        var el = document.querySelector('.'+contentClass);
        if (el === null) {
            next();
            return;
        }

        // hide, unbind, remove
        animateOut(el);
        removeHandlers();

        next();
    };
}
