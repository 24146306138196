var $ = require('jquery');
var Hammer = require('hammerjs');

module.exports = instance;

function instance() {

	var _this = this;

	var cursor = {x: 0, y: 0};
	var fullscreen = false;
	var value = 1;
	var offset = 1.6;
	var x;
	var y;
	var width ;
	var height;
	var set = false; // Flag for fullscreen

	var _window = $(window);
	var activeIndex = 0;

	var _nav = $('#navigation');
	var _title = _nav.find('.title, .icon-cross');
	var _burger = _nav.find('.burger-menu');
	var _social = _nav.find('.social').find('.icon').add('.mute').add('.share');

	_details = $('.item');
	_details = _details.last();

	var _leftPanel = _details.find('.left-panel');
	var _rightPanel = _details.find('.right-panel');
	var _zoom = _details.find('.zoom');
	var _swiper = _details.find('.swiper-container');
	var _container = _details.find('.container');
	var _zoomImage = _container.find('img');

	var leftElements = _leftPanel.find('input, textarea, span').add(_leftPanel.children().first());
	var rightElements = _rightPanel.find('.date, .dimensions, .title, .subtitle, .shadow');


	loadZoomImage = function(zoom, mobile) {

		var img = $(new Image());
		img.attr({
			src: _zoom[activeIndex].dataset.zoom,
			class: 'zoomImage'
		})

		.on('load', function(){

			_zoomImage = img;
			zoom.before(_zoomImage);

			// If the image finish loading before fullscreen animation end hide it
			if (!fullscreen) TweenMax.set(_zoomImage, { autoAlpha: 0 });
			else TweenMax.to(_zoomImage, 2, { autoAlpha: 1 });

			if (mobile) TweenMax.set(_zoomImage, { pointerEvents: 'none' });

			width = _zoomImage.width() * value;
			height = _zoomImage.height() * value;
		})

		.on('error', function(){
			console.log('fiche_zoom: loadZoomImage error');
			_zoomImage = zoom.parent().find('img:not(.zoomImage)');
		});
	};

	_this.setTimeline2 = function(progress) {

		timeline2 = new TimelineMax();
		_zoomImage = $('.zoomImage');

		// CALLBACK ----------------------------

		timeline2.eventCallback('onComplete', function() {
			TweenMax.to($('.zoomImage'), 2, { autoAlpha: 1 });
			fullscreen = true;
		})

		// BEGINNING ---------------------------

		// Animation panel
		timeline2.set($('body').add(_leftPanel), { overflow: 'hidden'});

		timeline2.set(_leftPanel, {
			overflow: 'hidden',
			width: "50%",
			paddingLeft: 140
		});

		timeline2.set(rightElements, { xPercent: 0 });

		// Animation navigation
		timeline2.set(_burger, {
			autoAlpha: 1,
			xPercent: 0
		});

		timeline2.set(_title.add(_social), {
			yPercent: 0
		});

		// END ---------------------------------

		// Animation panel
		timeline2.to(_leftPanel, 1, {
			width: "100%",
			paddingLeft: 0,
			ease: Expo.easeInOut,
			onComplete: function() {

				width = _zoomImage.width();
				height = _zoomImage.height();

				_window.on('mousemove', controlMouse);
			}
		});

		timeline2.staggerTo(rightElements, 0.8, {
			xPercent: 150,
			force3D: true,
			ease: Expo.easeInOut
		}, 0.05, "-=1.1");

		// Animation navigation
		timeline2.to(_burger, 0.8, {
			autoAlpha: 0,
			xPercent: -100,
			force3D: true,
			ease: Expo.easeInOut
		}, "-=1");

		timeline2.staggerTo(_title, 0.8, {
			autoAlpha: 0,
			yPercent: -100,
			force3D: true,
			ease: Expo.easeInOut
		}, 0.1, "-=1");

		$.fn.reverse = [].reverse;
		_social.reverse();

		timeline2.staggerTo(_social, 0.8, {
			autoAlpha: 0,
			yPercent: 200,
			force3D: true,
			ease: Expo.easeInOut
		}, 0.05, "-=1");

		set = true;

		timeline2.set(_nav, { autoAlpha: 0 });

		// Define timeline position
		if (progress === undefined) progress = 0;
		timeline2.progress(progress);
	}

	_this.displayFullscreen = function() {

		var _self = $(this); // zoom
		var container = _self.parent();

		// If HD image hasn't been loaded
		if ( !container.find('.zoomImage').length ) loadZoomImage(_self);

		// Set timeline2 once
		if (!set) _this.setTimeline2();

		// Disable zoom for small screen
		if (_window.width() < 765) return;

		// Reset scroll
		_zoom.scrollTop(0);
		cursor = { x: 0, y: 0 };

		if (!fullscreen && set) {

			if (!_swiper.length) {
				_zoom.on('scroll', scrollZoom);
				_zoom.addClass('scroll');
			} else {
				_zoom.addClass('exit');
			}

			timeline2.play();

		} else {

			_zoom.removeClass('exit');
			_zoom.removeClass('scroll');

			_window.off('mousemove', controlMouse);
			_zoom.off('scroll', scrollZoom);

			fullscreen = false;
			TweenMax.set($('.zoomImage'), { autoAlpha: 0 });
			timeline2.reverse();

			// Reset container position
			TweenMax.to(_container, 1, {
				scale: 1,
				x: 0,
				y: 0,
				force3D: true,
				ease: Expo.easeInOut
			});

			// Reset scale value
			value = 1;
		}
	}

	_this.selectActive = function(index) {

		activeIndex = index;

		var _active = $('.swiper-slide-active');
		var _zoom = _active.find('.zoom');

		// If the hd image hasn't been loaded, load it
		if (!_active.find('.zoomImage').length) loadZoomImage(_zoom);
		else {

			_zoomImage = _active.find($('.zoomImage'));
			TweenMax.to(_zoomImage, 2, { autoAlpha: 1 });
			width = _zoomImage.width() * value;
			height = _zoomImage.height() * value;
		}

		cursor.x = 0;
		cursor.y = 0;
	}

	_this.resetControl = function() {

		cursor.x = 0;
		cursor.y = 0;

		updateMouse(true)
	}

	function initGesture(container) {

		var _img  = container.find('img').last();
		var _zoom = container.find('.zoom');

		var scale = 1;
		var startScale;

		var viewWidth  = _window.width();
		var viewHeight = _window.height() - $('#navigation').height();
		var imgWidth   = _img.width();
		var imgHeight  = _img.height();

		var startTouchX, startTouchY;
		var startPosX, startPosY;

		var posX = 0;
		var posY = 0;

		var distX, distY;
		var offsetX, offsetY;

	 
		var hammertime = new Hammer(_img[0]);
		hammertime.get('pinch').set({ enable: true });

		hammertime.on('pinchstart', function(e) {

			// Enable hd image
			// fullscreen = true;
			// If HD image hasn't been loaded
			// if (!container.find('.zoomImage').length) loadZoomImage(_zoom, true);

			// Update start scale
			startScale = scale;

			// Avoid events conflict
			_img.off('touchmove', onTouchMove);
		});

		hammertime.on('pinchend', function(e) {

			// Reset touch event after 500ms
			TweenMax.delayedCall(0.5, function() {
				_img.on('touchmove', onTouchMove);
			});
		});

		hammertime.on('pinch', function(e) {

			// Update current scale
			scale = startScale * e.scale;

			// Scale limits
			if (scale < 1) scale = 1;
			if (scale > 3) scale = 4;

			getOffset();

			// Center image if it is smaller than the view
			if (offsetX < 0) posX = 0;
			if (offsetY < 0) posY = 0;

			// Update container position
			update();
		});

		function onTouchStart(e) {

			var target = e.originalEvent.touches[0];

			// Get the first position of the touch event
			startTouchX = target.clientX;
			startTouchY = target.clientY;

			// Update start position
			startPosX = posX;
			startPosY = posY;

			e.preventDefault();
		};

		function onTouchMove(e) {

			var target = e.originalEvent.touches[0];

			// Get distance of the touch event
			distX = target.clientX - startTouchX;
			distY = target.clientY - startTouchY;

			// Enable horizontal translation only if a part of the image is out of the view
			if (offsetX > 0) {

				// Add distance to the start position
				posX = startPosX + distX;

				// Translation limits
				if (Math.abs(posX) > offsetX) posX > 0 ? posX = offsetX : posX = -offsetX;
			}

			// Enable vertical translation only if a part of the image is out of the view
			if (offsetY > 0) {

				// Add distance to the start position
				posY = startPosY + distY;

				// Translation limits
				if (Math.abs(posY) > offsetY) posY > 0 ? posY = offsetY : posY = -offsetY;
			}

			// Update container position
			update();

			e.preventDefault();
		};

		function getOffset() {

			// Get the offset between image size and window size
			offsetX = (_img.width() * scale - viewWidth) / 2;
			offsetY = (_img.height() * scale - viewHeight) / 2;
		}

		function update() {

			TweenMax.to(container, 1.5, {
				scale   : scale,
				x       : posX,
				y       : posY,
				force3D : true,
				ease    : Expo.easeOut
			});
		};

		getOffset();
		_img.on('touchstart', onTouchStart);
		_img.on('touchmove', onTouchMove);
	
	}

	if (_window.width() < 765) initGesture(_container);

	var scrollZoom = function(e) {

		e.preventDefault();

		value = _zoom.scrollTop() / _zoom.height() + 1;

		width = _zoomImage.width() * value;
		height = _zoomImage.height() * value;

		updateMouse();
	}

	var controlMouse = function(e) {

		e.preventDefault();

		// Limits
		x = (width - _window.width()) / 100;
		y = (height - _window.height()) / 100;

		if (x < 0) x = 1;
		if (y < 0) y = 1;

		if (width > $(window).width()) cursor.x = (50 - e.clientX / _window.width() * 100) * x * offset;
		if (height > $(window).height()) cursor.y = (50 - e.clientY / _window.height() * 100) * y * offset;

		updateMouse();
	}

	var updateMouse = function(reset) {

		if (reset) _window.off('mousemove', controlMouse);

		TweenMax.to(_container, 1.5, {
			scale: value,
			x: -cursor.x,
			y: -cursor.y,
			force3D: true,
			ease: Expo.easeOut,
			onComplete: function() {
				if (!reset) return
				_window.on('mousemove', controlMouse);
			}
		})
	}

}
