var TweenMax = require('gsap');
var $ = require('jquery');
var menu = require('modules/menu.js');

var attachFastClick = require('lib/fastclick');
attachFastClick(document.body);

var _window = $(window);
var WIDTH;
var _nav;
var _subtitle;
var _title;
var _burger;
var _social;
var _mute;
var timeline;
var direction;
var navAnimDuration
;

var _socialResponsive;
var _socialIcon;
var open = false;

var buttonState; /* Store button state for resize */

function openSocial() {

    if (!open) {
        TweenMax.to(_socialResponsive, 0.8, {
            yPercent: 0,
            force3D: true,
            ease: Expo.easeOut
        })

        open = true;

    } else {

        TweenMax.to(_socialResponsive, 0.5, {
            yPercent: -200,
            force3D: true,
            ease: Expo.easeIn
        })
        open = false;
    }
}

function setVerticalAnimation() {

    console.log('navigation:setVerticalAnimation');

    // Prepare timeline for animateIn
    timeline = new TimelineMax();

    // BEGINNING OF THE ANIMATION

    timeline.set(_subtitle.find('.title, .exit-filter'), { autoAlpha: 0 });
    timeline.set(_nav, { autoAlpha: 1 });

    // Hide each elements
    timeline.set(_title, { autoAlpha: 0, yPercent: -100 });
    timeline.set(_burger, { autoAlpha: 0, xPercent: -100 });
    timeline.set(_social, { autoAlpha: 0, yPercent: 100 });

    // END OF THE ANIMATION
    timeline.to(_burger, 1, {
        autoAlpha: 1,
        xPercent: 0,
        force3D: true,
        clearProps: 'transform',
        ease: Expo.easeOut
    });

    timeline.staggerTo(_title, 1, {
        autoAlpha: 1,
        yPercent: 0,
        force3D: true,
        clearProps: 'transform',
        ease: Expo.easeOut
    }, 0.2, "-=1");

    timeline.staggerTo(_social, 1, {
        autoAlpha: 1,
        yPercent: 0,
        force3D: true,
        clearProps: 'transform',
        ease: Expo.easeOut
    }, 0.2, "-=1");

    // timeline.duration(); // this value is used in fuche?j: addHandler
    timeline.progress(1)
}

function setHorizontalAnimation() {

    console.log('navigation:setHorizontalAnimation');

    // Prepare timeline for animateIn
    timeline = new TimelineMax();

    // BEGINNING OF THE ANIMATION
    timeline.set(_subtitle.find('.title, .exit-filter'), { autoAlpha: 0 });
    timeline.set(_nav, { autoAlpha: 1 });

    timeline.set(_title, { autoAlpha: 0, xPercent: -100 });
    timeline.set(_burger, { autoAlpha: 0, yPercent: -100 });
    timeline.set(_social, { autoAlpha: 0, xPercent: 100 });

    // END OF THE ANIMATION
    timeline.staggerTo(_title, 1, {
        autoAlpha: 1,
        xPercent: 0,
        force3D: true,
        ease: Expo.easeOut
    }, 0.2);

    timeline.to(_burger, 1, {
        autoAlpha: 1,
        yPercent: 0,
        force3D: true,
        ease: Expo.easeOut
    }, "-=1");

    timeline.staggerTo(_social, 1, {
        autoAlpha: 1,
        xPercent: 0,
        force3D: true,
        ease: Expo.easeOut
    }, 0.2, "-=1");

    // timeline.duration(); // this value is used in fuche?j: addHandler
    timeline.progress(1)
}

function resize() {

    WIDTH = _window.width()

    if (WIDTH <= 765 && direction !== 'horizontal') {
        direction = 'horizontal';
        setHorizontalAnimation()
    } else if (WIDTH >= 765 && direction !== 'vertical') {
        direction = 'vertical';
        setVerticalAnimation()
        _subtitle.insertBefore(_burger);
    }

    exports.buttonState(buttonState)
}

function animateIn() {
}

exports.buttonState = function (state) {

    console.log('navigation: buttonState ' + state)
    // buttonState() function is called in each init to
    // control state of filters and back button and their container .subtitle

    var _subtitle = _nav.find('.subtitle');
    var _filters = _nav.find('.filters');
    var _back = _nav.find('.back');
    var filterName = _subtitle.find('.title, .exit-filter');

    buttonState = state
    TweenLite.set(_back, {yPercent: 0})

    switch (state) {

        // Case 1: Hide all : filters, back button and filter name
        // (Bio, Atelier, Contact & grille Actu)
        case 1 :

            TweenMax.to(_filters.add(_back).add(filterName), 0.6, { autoAlpha: 0, ease: Expo.easeOut });
            break;

        // Case 2: Hide filters button and filterName, show back button
        // (fiche Oeuvres et Actu)
        case 2 :

            TweenMax.to(_back, 0.6, { autoAlpha: 1, ease: Expo.easeOut });
            TweenMax.to(_filters.add(filterName), 0.6, { autoAlpha: 0, ease: Expo.easeOut });
            break;

        // Case 3: Show filters button, hide back button and filterName
        // (grilleOeuvres)
        case 3 :

            TweenMax.to(_back.add(filterName), 0.6, { autoAlpha: 0, ease: Expo.easeOut });
            TweenMax.to(_filters, 0.6, { autoAlpha: 1, ease: Expo.easeOut });
            break;

        // Case 4: Show filterName and hide, filter and back button
        case 4 :

            TweenMax.to(filterName, 0.6, { autoAlpha: 1, ease: Expo.easeOut });
            TweenMax.to(_filters.add(_back), 0.6, { autoAlpha: 0, ease: Expo.easeOut });
            break;

        default:
            return;
    }

    // Control state of subtitle container: hide it in mobile layout
    switch (state) {

        // Case 1: Hide subtitle
        // (Bio, Atelier, Contact & grille Actu
        case 1 :

            if (WIDTH <= 765) TweenMax.to(_subtitle, 0.6, { width: 0, ease: Expo.easeOut });
            else TweenMax.to(_subtitle, 0.6, { width: '100%', ease: Expo.easeOut });
            break;

        // Case 1 and 3: Show subtitle
        // (fiche Oeuvres et Actu, & grille Oeuvres)
        case 2, 3 :

            if (WIDTH <= 765) TweenMax.to(_subtitle, 0.6, { width: 40, ease: Expo.easeOut });
            else TweenMax.to(_subtitle, 0.6, { width: '100%', ease: Expo.easeOut });
            break;

        default :
            return;
    }
}

exports.init = function() {

    _nav = $('#navigation');
    _subtitle = _nav.find('.subtitle');
    _title = _nav.find('> .title');
    _subtitle = _nav.find('.subtitle');
    _burger = _nav.find('.burger-menu');
    _social = _nav.find('.social .icon').add('.mute');
    _mute = _nav.find('.mute')

    _socialResponsive = $('#social-responsive');
    _socialIcon = $('.icon-soc');

    if (!_nav.length) return;
    console.log('navigation:init');

    // addHandlers
    resize();
    _window.on('resize', resize)
    _burger.find('svg').on('click', function() { menu.open(0) });
    _mute.on('click', function() { $(document).trigger('mute') })

    $(document).on('navigation:animateIn', exports.open);
    $(window).on('popstate', exports.open);

    $(document).on('navigation:changeTitle', function(){
        if ((arguments[1] == '') || (''+_title.find('span').text() == ''+arguments[1])) return;
        _title.find('span').text(arguments[1]);
    });

    if (_socialResponsive.length) {
        _socialIcon.on('click', openSocial)
        TweenMax.set(_socialResponsive, { yPercent: -200 })
    };

    $(document).on('navigation:close', function() {
        if(timeline) timeline.reverse();
    })
}

exports.open = function() {
    console.log('navigation: open');
    $(document).trigger('navigation:changeTitle')
    timeline.restart();
}
