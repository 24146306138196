var xhr = require('xhr');
var TweenMax = require('gsap');
var Mustache = require('mustache');
// var classList = require('dom-classlist');
var navigation = require('modules/navigation.js');

module.exports = function() {
    var _this = this;
    var data;

    var contentClass = 'grid';
    var contentTag = 'div';
    var container;
    var content;
    var isServerRendered = false;

    // view specifics
    var $ = require('jquery');
    var Packery = require('packery_edited');
    var _ = require('lodash');

    // template / partials
    var template = require('views/grid.mustache');
    // DOM elements
    var _window = $(window);
    var _nav;
    var _grid;
    var _art;
    var _item;
    var _img;
    var _subtitle;

    // grid
    var temp;
    var gutter = 10;
    var numCol = 4;

    // View specific functions

    function instagramTitle() {

        _art.each(function(index) {
            var _this = $(this);
            var _title = _this.find('.title').text();
            var split = _title.split(/(#|@)/);
            var title = [];
            var subtitle = [];

            // Separate title and subtitle from data
            for (i = 0 ; i < split.length ; i++) {
                if ( split[i] == '@') {
                    subtitle.push(split[i] + split[i+1])
                } else if ( split[i] == '#') {
                    title.push(split[i] + split[i+1])
                }
            }

            _this.find('.title').text(title.join(' '));
            _this.find('.subtitle').text(subtitle.join(' '));

        });
    }

    function wrapElements() {

        // Restructure
        temp = numCol;
        $('.wrapper').removeClass('wrapper');

        // browse elements by step
        var step = 5
        for (i = 0 ; i < _art.length ; i += step ){

            // create bigger element to wrap small elements
            wrapper = $("<div>", {class: "wrapper yes"});

             // create bigger element to wrap small elements
            if (i % 10 == 0) {
                wrapper.append(_art.slice(i + 1, i + 5));
                _art.eq(i).after(wrapper);
                _art.eq(i).addClass('yes');
            }
            else {
                wrapper.append(_art.slice(i, i + 4));
                _art.eq(i + numCol).before(wrapper);
                _art.eq(i + numCol).addClass('yes');
            }
        }
    }

    // framework functions
    //------------- ENTER
    var init = function () {

        // Collect DOM elements/nodes
        _nav = $('#navigation');
        _grid = $(content);
        _art = $('.art');
        _item = $('.item');
        _img = _grid.find('img');
        _subtitle = _nav.find('.subtitle');

        if (!_grid) return;
        console.log('instagram-list: init');

        // view specific CSS hooks
        $(container).removeClass().addClass('view-grid');

        if ( ! _nav.find('.icon-cross').hasClass('exit-filter')){
            _nav.find('.icon-cross').addClass('exit-filter');
        }

        // set styles :
        // Navigation
        // TweenMax.set(_subtitle.find('.title, .exit-filter'), { autoAlpha: 0, yPercent: -50 });
        TweenMax.set(_subtitle, { autoAlpha: 0 });

        // styles needed before performing view's animateIn
        if(isServerRendered){
            TweenMax.set(content, {autoAlpha: 1});
            TweenMax.set('.loader-spinner', {autoAlpha: 0});
        }

        instagramTitle();
        wrapElements();
    }

    var animateIn = function () {

        console.log('instagram-list: animateIn')

        TweenMax.to(content, 1, {
            autoAlpha: 1,
            ease: Expo.easeOut
        });
    }

    var resize = function() {

        if (_window.width() < 640) { numCol = 2 }
        else { numCol = 4 }

        $('.yes').each(function(index) {

            var _this = $(this);
            var columnWidth = Math.floor((_grid.width() - (gutter * (numCol - 1))) / numCol);

            _this.width(columnWidth * 2 + gutter);

            // Add margin-right if elements is on the left
            if(index % 2 == 0) _this.css({ marginRight: gutter });

            if (_window.width() < 640 && !_this.hasClass('wrapper')) { _this.css({ marginBottom: gutter }) }
            else { _this.css({ marginBottom: 'initial' }) }

            // Add margin-bottom if small elements is on the left
            _this.find('.art').css({ marginBottom: gutter });

            // Set small elements width
            _this.find('.art').each(function(index) {

                var _this = $(this);
                _this.width(columnWidth);

                // Add margin-right if elements is on the left
                if (index % 2 == 0) _this.css({ marginRight: gutter });

            })
        });
    }

    var addHandlers = function () {

        console.log('instagram-list: addHandlers')
        _window.on('resize', resize);
    }

    //------------- EXIT
    var animateOut = function () {
        console.log('instagram-list: animateOut');

        TweenLite.to(content, 1, { autoAlpha: 0, onComplete: function() {
            // exit
            if(content != null){
                content.parentNode.removeChild(content)
            }
        }});

        $(document).off('list-instagram:exit', animateOut);
    }

    var removeHandlers = function () {
        console.log('instagram-list: removeHandlers')

        _window.off('resize', resize);

        // collect collectGarbage
        _nav = null;
        _grid = null;
        _art = null;
        _item = null;
        _img = null;
        _subtitle = null;
    }

    // 1. query the model
    _this.load = function (ctx, next){
        var path = ctx.pathname;
        var api = location.origin + path + '?showJSON';

        // If another grid present, hide
        $(document).trigger('grid:exit');

        // exit if data cached
        if (ctx.state.list){
            next();
            return;
        }
        console.log('instagram-list: load');

        xhr({ url: api }, function (err, resp, body) {
            ctx.state.list = JSON.parse(resp.body);
            ctx.save(); // cache data / save state
            next();
        });
    }

    // 2. create the view from the model ( render, init, add handlers, animate In )
    _this.enter = function (ctx, next){
        var menu = ctx.state.list.$FO_MENU[2];
        var titreRubrique;

        data = ctx.state.list.$FO_ITEM;

        // collect elements
        container = document.getElementById('page');

        // Check for acces-direct
        var accesDirect = document.body.querySelector('.acces-direct');

        // publish rubrique to navigation module
        titreRubrique = (typeof menu[data.template] !== 'undefined') ? menu[data.template].titre : '';
        $(document).trigger('navigation:changeTitle', titreRubrique);

        // exit if DOM rendered by server
        if( accesDirect !== null ) {

            // Remove flag div
            accesDirect.parentNode.removeChild(accesDirect);

            content = container.querySelector('.'+contentClass);
            isServerRendered = true;

            data.isLive = $(content).hasClass('live_85eb4');

            init();
            resize();
            addHandlers();

            return;
        }

        // state
        console.log('instagram-list: render');
        isServerRendered = false;

        // use data
        data.isLive = true;

        var list = data.List;
        for (var i = list.length - 1; i >= 0; i--) {
            list[i].Attribut = _.values(list[i].Attribut);
        };
        data.List = list;
        list = null;

        // render
        content = document.createElement(contentTag);
        content.innerHTML = Mustache.render(template, data);
        content = content.children[0]; // get the actual component container

        // append
        $(content).addClass(contentClass);
        container.appendChild(content);

        init(); // get elts, if(!isServerRendered) : set styles before animateIn
        resize(); // size dependant layout & styles
        addHandlers();

        animateIn();
    }

    // 3. destroy the view ( animate Out, remove handlers, delete DOM nodes, nullify vars )
    _this.exit = function (ctx, next){
        console.log('instagram-list: exit')

        if (content === null || _nav===null) {
            next();
            return;
        }

        // unbind, hide, remove
        removeHandlers();
        $(document).on('grid:exit', animateOut);;

        next();
    }

}
